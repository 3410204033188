
















































import { Vue } from "vue-property-decorator";
import Component from "vue-class-component";

import { technologies } from "@/mock/technologies";
import { RouterPathName } from "@/enums/router-path-name.enum";

@Component({
  components: {
    ProjectsSwiperGallery: () =>
      import(
        /* webpackChunkName: 'home-page-projects-gallery' */ "@/components/project/ProjectsSwiperGallery.vue"
      ),
    TechnologiesGrid: () =>
      import(
        /* webpackChunkName: 'home-page-technologies-grid' */ "@/components/technology/TechnologiesGrid.vue"
      )
  }
})
export default class HomePage extends Vue {
  /** Available technologies */
  technologies = technologies;

  /** Router path names dictionary */
  routerPathName = RouterPathName;
}
